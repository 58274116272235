<template>
  <section>
    <Toast />
    <div class="w-full bg-white py-3 rounded-lg">
      <button @click="regresar" class="flex px-2 items-center">
        <div class="h-5 w-5 bg-gray-300 rounded-full items-center justify-center flex">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
          </svg>
        </div>
        <p class="text-gray-600 text-xs font-medium mx-2">Atras</p>
      </button>
      <div class="w-full xl:flex xl:gap-12 p-2">
        <div class="w-full xl:w-5/12">
          <div class="flex-wrap xl:flex-nowrap flex items-center">
            <div class="flex items-center w-6/12 md:w-4/12" >
              <div>
                <img class="w-10/12" src="../../../../../../assets/images/crear-ov-logo.svg" alt="">
              </div>
              <div class="block ml-2">
                <p class="text-lg text-blue-800 leading-8">CREACIÓN</p>
                <p class="text-xl text-blue-800 font-extrabold leading-8">ORDEN DE VENTA</p>
              </div>
            </div>
            <div class="text-center block w-3/12 md:w-2/12">
              <p class="text-xs text-gray-600">N° OV</p>
              <p class="font-bold text-blue-800 mt-2">{{ numeroOv }}</p>
            </div>
            <div class="text-center block w-3/12 md:w-2/12">
              <p class="text-xs text-gray-600">Estado</p>
              <p class="font-bold text-blue-800 mt-2">Abierta</p>
            </div>
            <div class="block w-6/12 md:w-2/12 pr-1">
              <p class="text-gray-600 text-xs">Fecha Cont./Doc</p>
              <input :class="!ordenesStore._header.fechaCont && creandoOrden ? 'border-red-600' : 'border'" v-model="ordenesStore.header.fechaCont" class="border text-gray-600 rounded-md p-1 text-xs" :min="configSapStore._fecha_minima_contabilizacion" :max="dayjs().format('YYYY-MM-DD')" type="date">
              <span v-if="!ordenesStore._header.fechaCont && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
            </div>
          </div>
        </div>
        <div class="w-full xl:w-7/12 mt-2 items-center">
          <div class="flex-wrap xl:flex-nowrap flex items-center xl:justify-end gap-2">
            <div class="block w-full md:w-6/12 xl:w-3/12">
              <p class="text-gray-600 text-xs">Cod cliente / Entidad</p>
              <Dropdown
                v-model="ordenesStore.header.cliente"
                :options="clientesStore._listado_clientes"
                :filter="true"
                optionLabel="name"
                placeholder="Seleccione"
                panelClass="text-xs w-20"
                @change="cambiarCliente"
                class="text-xs w-full rounded-md border-gray-300"
              >
                <template #option="slotProps">
                  <div :style="{'text-wrap': 'wrap'}">
                    <span>{{slotProps.option.name}}</span>
                  </div>
                </template>
              </Dropdown>
              <span v-if="!ordenesStore._header.cliente && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
            </div>
            <div class="block w-full md:w-6/12 xl:w-3/12">
              <p class="text-gray-600 text-xs">Bodega:</p>
              <Dropdown
                v-model="bodegasStore.bodega_seleccionada"
                :options="listadoBodegas"
                :filter="true"
                optionLabel="name"
                placeholder="Seleccione"
                panelClass="text-xs w-20"
                @change="pacientesStore.paciente_seleccionado = ''"
                class="text-xs w-full rounded-md border-gray-300"
                id="bodega"
                @hide="cambiarBodega"
              >
                <template #option="slotProps">
                  <div :style="{'text-wrap': 'wrap'}">
                    <span>{{slotProps.option.name}}</span>
                  </div>
                </template>
              </Dropdown>
              <span v-if="!ordenesStore._header.bodega && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
            </div>
            <div class="block w-full md:w-6/12 xl:w-3/12">
              <div class="w-full flex mb-1">
                <p class="text-xs text-gray-600">Motivo Autorización:</p>
              </div>
              <div class="w-full mb-1">
                <Dropdown
                  v-model="ordenesStore.udf.mot_autoriza"
                  :options="motivosAutorizacionStore._listado_motivos_autorizacion"
                  optionLabel="Nombre"
                  placeholder="Seleccione"
                  panelClass="text-xs"
                  :emptyMessage="ordenesStore.header.cliente ? 'El cliente seleccionado no cuenta con contrato activo' : 'Debes seleccionar un cliente primero'"
                  class="text-xs w-full rounded-md border-gray-300"
                  @hide="cambiarMotivoAutorizacion"
                  id="motivoAutorizacion"
                />
                <span v-if="!ordenesStore._udf.mot_autoriza && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
              </div>
            </div>
            <div class="w-full md:w-6/12 xl:w-3/12 mb-1 px-0">
              <p class="text-gray-600 text-xs">N° Ident Paciente</p>
              <div class="w-  full flex gap-1">
                <Dropdown
                  @change="pacientesStore.paciente_seleccionado = ''"
                  class="w-2/12 chevron-dropdown-none rounded-md border-gray-300 text-xs"
                  v-model="tipoId"
                  :options="tiposIdentificacion"
                  @hide="activarSelect('numeroIdPaciente', 'autocomplete', pacientesStore._paciente_seleccionado)"
                  optionLabel="abreviatura"
                  optionValue="abreviatura"
                  id="tiposIdentificacion"
                  panelClass="text-xs"
                >
                  <template #option="slotProps">
                    <p>{{ slotProps.option.descripcion }}</p>
                  </template>
                </Dropdown>
                <AutoComplete
                  :class="pacientesStore.paciente_seleccionado ? 'w-9/12' : 'w-10/12'"
                  class="text-xs"
                  panelClass="text-xs w-8"
                  inputClass="text-xs w-full"
                  :inputStyle="{ 'border': '1px solid rgba(226, 232, 240, var(--tw-border-opacity))' }"
                  v-model="pacientesStore.paciente_seleccionado"
                  :suggestions="pacientesStore._listado_pacientes"
                  @complete="buscarPaciente"
                  @item-select="setPaciente"
                  field="name"
                  :forceSelection="true"
                  id="numeroIdPaciente"
                  v-tooltip.top="`${pacientesStore._paciente_seleccionado && pacientesStore._paciente_seleccionado.U_ACS_PrimerNmb ? pacientesStore._paciente_seleccionado.name : ''}`"
                >
                <template #item="slotProps">
                  <div :style="{'text-wrap': 'wrap'}">
                    <span>{{slotProps.item.name}}</span>
                  </div>
                </template>
                </AutoComplete>
                <button v-if="pacientesStore._paciente_seleccionado && pacientesStore._paciente_seleccionado.U_ACS_PrimerNmb" class="w-1/12" @click="openModalEditarPaciente">
                  <i class="pi pi-pencil text-xs text-blue-600"></i>
                </button>
              </div>
              <span v-if="!pacientesStore.paciente_seleccionado && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block md:flex">
        <div class="bg-white px-2" :class="`content ${udfShow ? 'w-9/12':''}`">
          <Udf />
          <Body/>
          <InfoPaciente/>
        </div>
      </div>
    </div>
    <ModalEditarPaciente />
  </section>
</template>
<script>
  import { defineAsyncComponent, ref, onMounted, computed } from 'vue'
  import '../../../../../../../node_modules/animate.css/animate.css'
  import '../../../../../../layouts/main-menu/Main.vue'
  import { useClientesStore } from '../../../stores/clientes.store'
  import { useMotivosAutorizacionStore } from '../../../stores/motivos_autorizacion.store'
  import { usePacientesStore } from '../../../stores/pacientes.store'
  import { useOrdenesStore } from '../../../stores/ordenes.store'
  import { useCalculoCuotaStore } from '../../../stores/calculo_cuota.store'
  import { useRegimenStore } from '../../../stores/regimen.store'
  import { useConfigGlobal } from '../../../stores/config-ov.store'
  import { useMipresStore } from '../../../stores/mipres.store'
  import { useToast } from 'primevue/usetoast'
  import { useRouter } from 'vue-router'
  import { useConfigSap } from '../../../stores/configuracion-campos-ov.store'
  import LocalizacionService from '../../../services/localizacion.service'
  import { useBodegasStore } from '../../../stores/bodegas.store'
  import ModalEditarPaciente from '../../../components/modalEditarPaciente.vue'
  import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  export default {
    name: 'ordenesCreacion',
    components: {
      Udf: defineAsyncComponent(() => import('./components/udf.vue')),
      Body: defineAsyncComponent(() => import('./components/body')),
      InfoPaciente: defineAsyncComponent(() => import('./components/info_paciente.vue')),
      ModalEditarPaciente
    },
    setup () {
      // Services
      const _LocalizacionService = ref(new LocalizacionService())
      const toast = useToast()
      const router = useRouter()
      // Stores
      const bodegasStore = useBodegasStore()
      const clientesStore = useClientesStore()
      const ordenesStore = useOrdenesStore()
      const motivosAutorizacionStore = useMotivosAutorizacionStore()
      const pacientesStore = usePacientesStore()
      const calculoCuotaStore = useCalculoCuotaStore()
      const regimenStore = useRegimenStore()
      const configGlobal = useConfigGlobal()
      const mipresStore = useMipresStore()
      const configSapStore = useConfigSap()
      // References
      const udfShow = ref(false)
      const tipoId = ref('CC')
      const listadoLocalizaciones = ref([])
      const listadoDepartamentos = ref([])
      const listadoCiudades = ref([])
      const bodegaBuscada = ref('')
      const actualizandoPaciente = ref(false)
      const generos = ref(['Femenino', 'Masculino'])
      const regimenes = ref(['Subsidiado', 'Contributivo'])
      const tooltips = ref({
        id_paciente: ''
      })
      const displayModalEditarPaciente = ref(false)
      // Computed
      const pacienteSeleccionado = computed(() => pacientesStore._paciente_seleccionado)
      const creandoOrden = computed(() => ordenesStore._creando_orden)
      const listadoBodegas = computed(() => bodegasStore._listado_bodegas)
      const numeroOv = computed(() => ordenesStore._ultima_orden)
      const tiposIdentificacion = computed(() => pacientesStore._tipos_identificacion)
      // Methods
      const buscarPaciente = () => {
        if (!ordenesStore._header.cliente) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un cliente primero', life: 3000 })
        if (!tipoId.value) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un tipo de identificación primero', life: 3000 })
        if (!ordenesStore.header.cliente) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un cliente para continuar', life: 3000 })
        if (!ordenesStore._udf.mot_autoriza) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un motivo de autorización para continuar', life: 3000 })
        const containsNumbers = (str) => {
          return /\d/.test(str)
        }
        if (pacientesStore._paciente_seleccionado.length > 2) {
          const consulta = containsNumbers(pacientesStore._paciente_seleccionado) ? { U_ACS_NmrIdentf: pacientesStore._paciente_seleccionado, U_ACS_TpoIdentf: tipoId.value, ClienteId: ordenesStore.header.cliente.Id, MotAutorizaId: ordenesStore._udf.mot_autoriza.Id } : { U_ACS_NmbPct: pacientesStore._paciente_seleccionado.toUpperCase(), U_ACS_TpoIdentf: tipoId.value, ClienteId: ordenesStore.header.cliente.Id, MotAutorizaId: ordenesStore._udf.mot_autoriza.Id }
          consulta.Anio = dayjs(ordenesStore._header.fechaCont).format('YYYY')
          pacientesStore.obtenerListadoPacientes(consulta)
        }
      }
      const obtenerLocalizaciones = (ubicacion) => {
        return _LocalizacionService.value.listarLocalizaciones(ubicacion).then(({ data }) => {
          listadoLocalizaciones.value = data
          return data
        })
      }
      const cambiarCliente = (event) => {
        configSapStore.validacionFechas()
        motivosAutorizacionStore.obtenerListadoMotivosAutorizacion({ ClienteId: ordenesStore._header.cliente.Id })
        clientesStore.cliente_seleccionado = ordenesStore._header.cliente
        if (motivosAutorizacionStore._motivo_autorizacion_seleccionado) {
          configGlobal.obtenerColumnasTabla({ cardCode: ordenesStore._header.cliente.CardCode, motAutoriza: motivosAutorizacionStore._motivo_autorizacion_seleccionado })
        }
        pacientesStore.paciente_seleccionado = ''
        activarSelect('bodega', 'select', ordenesStore._header.cliente ? bodegasStore._bodega_seleccionada : null)
      }
      const cambiarBodega = () => {
        ordenesStore.header.bodega = bodegasStore._bodega_seleccionada.WhsCode
        pacientesStore.paciente_seleccionado = ''
      }
      const regresar = () => {
        var menu = document.getElementsByClassName('side-nav')
        var namePage = document.getElementsByClassName('name-page')
        var content = document.getElementsByClassName('main')
        var main = document.getElementsByClassName('main')
        menu[0].classList.remove('hidden')
        namePage[0].classList.remove('hidden')
        content[0].classList.remove('p-2')
        main[0].classList.remove('px-2')
        router.push({ name: 'pharmasan.ventas.ov.listado' })
      }
      const openModalEditarPaciente = () => {
        obtenerLocalizaciones(pacienteSeleccionado.value.U_PHR_CodDane).then(() => {
          pacientesStore.paciente_seleccionado.zonificacion = listadoLocalizaciones.value[0]
          pacientesStore.display_editar_paciente = true
        })
      }
      const activarSelect = (id, type, value) => {
        if (!value) {
          setTimeout(() => {
            if (type === 'input') {
              document.getElementById(id).focus()
            } else if (type === 'autocomplete') {
              document.getElementById(id).click()
            } else {
              document.getElementById(id).click()
            }
          }, 300)
        }
      }
      const setPaciente = async () => {
        tooltips.value.id_paciente = pacientesStore._paciente_seleccionado.name
        pacientesStore.zonificacion_seleccionada = pacientesStore._paciente_seleccionado.Zonificacion
        pacientesStore.parametros_paginado_historia_clinica.filtros = { cc: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf, type_doc: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf }
        // Si el motivo de autorización ya esta seleccionado y es mipres validar si tiene mipres
        if (typeof pacientesStore._paciente_seleccionado === 'object' && (ordenesStore._udf.mot_autoriza.Codigo === '05' || ordenesStore._udf.mot_autoriza.Codigo === '06')) {
          await mipresStore.obtenerCodigos({ U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf, U_ACS_NmrIdentf: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf }).then((data) => {
              if (!data.length) {
                return Swal.fire({
                  title: 'Atención',
                  text: 'El paciente seleccionado no cuenta con mipres hasta la fecha',
                  icon: 'warning',
                  confirmButtonText: 'Ok'
                })
              }
            })
        } else {
          mipresStore.listado_codigos = []
        }
        pacientesStore.getHitoriaClinicaPaciente()
        obtenerLocalizaciones(pacientesStore._paciente_seleccionado.U_PHR_CodDane).then(() => {
          pacientesStore.localizacion_seleccionada = {
            Zonificacion: pacientesStore._paciente_seleccionado.U_ACS_Dpto_domicilio + ', ' + pacientesStore._paciente_seleccionado.U_ACS_Ciudad_domicilio,
            nomMpio: pacientesStore._paciente_seleccionado.U_ACS_Ciudad_domicilio,
            nomDpto: pacientesStore._paciente_seleccionado.U_ACS_Dpto_domicilio
          }
          pacientesStore.paciente_seleccionado.zonificacion = listadoLocalizaciones.value[0]
        })
        regimenStore.$patch((state) => {
          const regimenPrincipal = regimenStore._listado_regimen.find(a => a.nombre.toLowerCase() === pacientesStore._paciente_seleccionado.U_ACS_Regimen.toLowerCase())
          const array = [regimenPrincipal, ...regimenStore._listado_regimen.filter(a => a.PadreId !== regimenPrincipal.id && a.PadreId)]
          state.regimen_por_paciente = array
        })
        ordenesStore.udf.zonificacion = pacientesStore._paciente_seleccionado.Zonificacion
        ordenesStore.udf.localizacion = pacientesStore._paciente_seleccionado.Zonificacion
        ordenesStore.udf.ex_pac = pacientesStore._paciente_seleccionado.U_ACS_Exonerado
        ordenesStore.udf.cod_mpio = pacientesStore._paciente_seleccionado.U_PHR_CodDane
        ordenesStore.udf.regimen = regimenStore._listado_regimen.find(a => a.nombre.toLowerCase() === pacientesStore._paciente_seleccionado.U_ACS_Regimen.toLowerCase())?.id
        // Set porcentaje o cuota moderadora
        if (pacientesStore._paciente_seleccionado.ValorCuota) {
          ordenesStore.udf.porcentaje_cuota = 0
          if (ordenesStore._udf.mot_autoriza.CobroCuotaModeradora) {
            ordenesStore.udf.cuota_moderadora = pacientesStore._paciente_seleccionado.ValorCuota
          } else {
            toast.add({ severity: 'info', summary: 'Atención', detail: `El contrato activo del cliente ${ordenesStore._header.cliente.CardName} exonera la cuota moderadora para todos los pacientes cuando el motivo de autorización es ${ordenesStore._udf.mot_autoriza.Nombre}`, life: 8000 })
          }
        } else {
          ordenesStore.udf.cuota_moderadora = 0
          if (ordenesStore._udf.mot_autoriza.CobroCuotaRecuperacion) {
            ordenesStore.udf.porcentaje_cuota = ordenesStore._udf.mot_autoriza.Codigo === '06' || ordenesStore._udf.mot_autoriza.Codigo === '05' ? pacientesStore._paciente_seleccionado.ValorPorcentaje : 0
          } else {
            toast.add({ severity: 'info', summary: 'Atención', detail: `El contrato activo del cliente ${ordenesStore._header.cliente.CardName} exonera la cuota de recuperación para todos los pacientes cuando el motivo de autorización es ${ordenesStore._udf.mot_autoriza.Nombre} en nivel alto`, life: 8000 })
          }
        }
        if (ordenesStore._udf.porcentaje_cuota || ordenesStore._udf.cuota_moderadora) {
          ordenesStore.mostrar_calcular_cuota = true
        } else ordenesStore.mostrar_calcular_cuota = false
      }
      const cambiarMotivoAutorizacion = async () => {
        configSapStore.validacionFechas()
        // Validación mipres
        if (typeof pacientesStore._paciente_seleccionado === 'object' && (ordenesStore._udf.mot_autoriza.Codigo === '05' || ordenesStore._udf.mot_autoriza.Codigo === '06')) {
          ordenesStore.mostrar_calcular_cuota = true
          await mipresStore.obtenerCodigos({ U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf, U_ACS_NmrIdentf: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf }).then((data) => {
            if (!data.length) {
              return Swal.fire({
                title: 'Atención',
                text: 'El paciente seleccionado no cuenta con mipres hasta la fecha',
                icon: 'warning',
                confirmButtonText: 'Ok'
              })
            }
          })
        } else {
          mipresStore.listado_codigos = []
        }
        if (ordenesStore._udf.mot_autoriza) {
          const object = { cardCode: ordenesStore._header.cliente.CardCode, motAutoriza: ordenesStore._udf.mot_autoriza.Id }
          configGlobal.obtenerColumnasTabla(object)
          motivosAutorizacionStore.motivo_autorizacion_seleccionado = ordenesStore._udf.U_ACS_NmrIdentf
        }
      }
      onMounted(() => {
        ordenesStore.resetData()
        var menu = document.getElementsByClassName('side-nav')
        var namePage = document.getElementsByClassName('name-page')
        var content = document.getElementsByClassName('content')
        var main = document.getElementsByTagName('body')
        menu[0].classList.add('hidden')
        main[0].classList.add('px-2')
        namePage[0].classList.add('hidden')
        content[0].classList.add('p-2')
        ordenesStore.getUltimaOrden()
        clientesStore.obtenerListadoClientes()
        bodegasStore.obtenerListadoBodegasByUser()
        calculoCuotaStore.obtenerRangosNiveles()
        pacientesStore.obtenerTiposIdentificacion()
        configSapStore.obtenerListadoConfiguraciones()
      })
      return {
        bodegaBuscada,
        buscarPaciente,
        cambiarCliente,
        creandoOrden,
        displayModalEditarPaciente,
        generos,
        listadoBodegas,
        listadoCiudades,
        clientesStore,
        listadoDepartamentos,
        listadoLocalizaciones,
        numeroOv,
        openModalEditarPaciente,
        obtenerLocalizaciones,
        pacienteSeleccionado,
        regimenes,
        regresar,
        tipoId,
        tiposIdentificacion,
        udfShow,
        dayjs,
        actualizandoPaciente,
        activarSelect,
        cambiarBodega,
        setPaciente,
        tooltips,
        pacientesStore,
        ordenesStore,
        bodegasStore,
        configSapStore,
        motivosAutorizacionStore,
        cambiarMotivoAutorizacion
      }
    }
  }
</script>
<style scoped>
  ::v-deep(.p-dropdown ) {
    height: 1.8rem;
    padding: 0rem;
  }
  ::v-deep(.p-dropdown .p-inputtext) {
    font-size: 0.75rem;
    padding: 0.3rem;
  }
  ::v-deep(.p-inputtext) {
    font-size: 0.75rem;
    padding: 0.25rem;
  }
  ::v-deep(.chevron-dropdown-none .p-dropdown-trigger) {
    display: none !important;
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
